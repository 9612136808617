.page {
	min-height: 100vh;
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
}

.page h1 {
	transition: transform $transition-time ease-in-out, opacity $transition-time ease-in-out;
	transform: translate(0, 20px);
	opacity: 0;
	font-size: 15vw;

	span {
		transition: transform $transition-time ease-in-out, opacity $transition-time ease-in-out;
		transition-delay: $transition-time;
		transform: translate(1vw, 0);
		font-size: 1vw;
		position: absolute;
		top: 1.5vw;
		left: 4vw;
		opacity: 0;
		
		.active & {
			transform: translate(0, 0);
			opacity: 0.5;
		}
	}

	.active & {
		transform: translate(0, 0);
		opacity: 1;
	}
}

.page h2 {
	transition: transform $transition-time ease-in-out, opacity $transition-time ease-in-out;
	font-family: $font-family-sans-serif-alt;
	transform: translate(-20px, 0);
	opacity: 0;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 0.2vw;

	.active & {
		transition-delay: $transition-time;
		transform: translate(0, 0);
		opacity: 0.4;
	}

	&::before,
	&:after {
		transition: width $transition-time ease-in-out, opacity $transition-time ease-in-out;
		opacity: 0;
		content: "";
		width: 0;
		position: relative;
		top: -3px;
		margin: 0 10px;
		display: inline-block;
		border-top: 1px solid rgba(255,255,255,0.5);

		.active & {
			opacity: 1;
			transition-delay: $transition-time;
			width: 10px;
		}
	}
}


.page-info {
	min-height: 100vh;
	background: $gray-darker;
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    position: relative;
}

// TRANSITIONS FOR PAGE INFO
.sps {
	h2 {
		transition: transform $transition-time ease-in-out, opacity $transition-time ease-in-out;
		transition-delay: $transition-time;
		transform: translate(0, 30px);
		opacity: 0;
		font-size: 40px;
		top: 100px;
		position: relative;

		@include media-breakpoint-up(md) {
			top: 0;
		}

		span {
			display: block;
			font-size: 20px;
			color: $gray;
		}
	}

	// WHEN IN VIEW
	&.sps--blw {
		h2 {
			transform: translate(0, 0);
			opacity: 1;
		}
	}
}


.page--home {
	.page-ready & {
		h1 {
			transform: translate(0, 0);
			opacity: 1;
		}
		h1 span {
			transform: translate(0, 0);
			opacity: 0.5;
		}
	}
}

.page::before{
	content: "";
	transition: opacity $transition-time ease-in-out, transform ($transition-time * 30) ease-out;
	transition-delay: $transition-time;
	transform: scale(1);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	z-index: -1;

	.loaded & {
		opacity: 1;
		transform: scale(1.15);
	}
}

.page--home::before {
	background-image: url("../images/bg-red-room.jpg");
}

.page--bob::before {
	background-image: url("../images/bg-bob.jpg");
}

.page--the-man::before {	
	background-image: url("../images/bg-the-man.jpg");
}

.page--mike::before {
	background-image: url("../images/bg-mike.jpg");
}

.page--laura-palmer::before {
	background-image: url("../images/bg-laura-palmer.jpg");
}

.page--the-giant::before {
	background-image: url("../images/bg-the-giant.jpg");
}

.page--chester-desmond::before {
	background-image: url("../images/bg-chester-desmond.jpg");
}

.page--phillip-jefferies::before {
	background-image: url("../images/bg-phillip-jefferies.jpg");
}

.page--dale-cooper::before {
	background-image: url("../images/bg-dale-cooper.jpg");
}

.page-info-content {
	padding-top: 130px;
	padding-bottom: 200px;

	@include media-breakpoint-up(md) {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	&.bio {
		max-width: 1200px;
	}
}


.text-col {
	font-size: 12px;
	line-height: 1.8;
}

.text-col > p:first-of-type {
	font-size: 17px;
	line-height: 1.5;
}

.text-col > p:first-line {
	text-transform: uppercase;
}

cite {
	font-family: $font-family-serif;
	color: $gray;
	font-style: normal;
}

.bio-img-col {
	transition: transform $transition-time ease-in-out, opacity $transition-time ease-in-out;
	transition-delay: $transition-time + 0.2;
	opacity: 0;
	transform: translate(-20px, 0);

	@include media-breakpoint-down(sm) {
		max-width: 200px;
		margin: 10px auto 10px;
	}

	.sps--blw & {
		transition-delay: $transition-time;
		transform: translate(0, 0);
		opacity: 1;
	}
}

.bio-img {
	transition: filter $transition-time ease-in-out;
	border-radius: 100%;
	margin-bottom: 30px;
	filter: invert(0);
}

.img-info-link {
	display: block;
	position: relative;
	background: transparent;
	border: 0;
	outline: none !important;

	&::before,
	&::after {		
		content: "";
		transition: opacity $transition-time ease-in-out, transform $transition-time ease-in-out;
		transition-delay: $transition-time * 2;
		transform: translate(0,20px);
		opacity: 0;
		z-index: 1;

		.sps--blw & {
			opacity: 1;
			transform: translate(0,0);
		}
	}

	&::before {
		position: absolute;
		width: 30px;
		height: 30px;
		bottom: 15px;
		border-radius: 100%;
		background: $brand-primary;
		left: 50%;
		margin-left: -15px;
		animation: shadow-pulse 2s ease-in-out infinite;
		transform-origin: 50% 50%;
	}

	&::after {
		width: 0;
		height: 0;
		position: absolute;
		left: 50%;
		margin-left: -2px;
		bottom: 25px;
		border-width: 5px;
		border-style: solid;
		display: block;
		border-color: transparent transparent transparent rgba(255,255,255,0.5);
	}

	&:hover {
		cursor: pointer;

		img {
			filter: invert(1);
		}
	}
}

// Shadow Pulse
// Animation keyframes
@keyframes shadow-pulse {
    0% {
    box-shadow: 0 0 0 0 rgba(255,255,255, 0.2);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(255,255,255, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}


// DL Row
.text-col dl {
	@extend .row;
	@extend .no-gutters;
	margin-bottom: 5px;
}

// DT title
.text-col dt {
	@extend .col-2;
	text-transform: uppercase;
	text-align: right;
	color: $gray;
	margin-right: 10px;
	font-size: 10px;
    line-height: 21px;
    padding: 0;

    @include media-breakpoint-up(md) {
		font-size: 11px;
		letter-spacing: 1px;
		line-height: 21px;
    }
}

// DD Fefinition
.text-col dd {
	@extend .col-9;
	font-size: 13px;
	margin: 0;
}

