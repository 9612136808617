.btn-scroll {
	transition: background ($transition-time / 2) ease-out;
	width: 24px;
	height: 24px;
	background: transparent;
	border: 2px solid rgba(255,255,255,0.5);
	transform: rotate(45deg);
	position: absolute;
	left: 50%;
	margin-left: -12px;
	box-shadow: none;

	&:hover {
		cursor: pointer;
		background: $white;
	}

	// before, after
	&::before {
		content: "";
		width: 10px;
		height: 10px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -5px;
		margin-top: -5px;
		border-width: 2px;
		border-style: solid;
	}
}

.btn-scroll--down {
	bottom: 40px;	

	&::before {
		border-color: transparent rgba(255,255,255,0.5) rgba(255,255,255,0.5) transparent;
		animation: down-arrow $transition-time ease-in-out 2s infinite alternate;
	}
	&:hover::before {
		border-color: transparent $gray-darker $gray-darker transparent;
	}
}

@keyframes down-arrow {
    from {
      transform: translate(1px, 1px);
    }
    to {
      transform: translate(-1px, -1px);
    }
}


.btn-scroll--up {
	top: 40px;

	&::before {
		border-color: rgba(255,255,255,0.5) transparent transparent rgba(255,255,255,0.5);
		animation: up-arrow $transition-time ease-in-out 2s infinite alternate;
	}

	&:hover::before {
		border-color: $gray-darker transparent transparent $gray-darker;
	}
}

@keyframes up-arrow {
    from {
      transform: translate(-1px, -1px);
    }
    to {
      transform: translate(1px, 1px);
    }
}

.btn-next {
	font-size: 10px;
    position: absolute;
    bottom: 80px;
    padding: 3px 10px;
    border: 1px solid $white;
    color: $white;
    letter-spacing: 2px;
    text-transform: uppercase;

	@include media-breakpoint-up(md) {
		bottom: 60px;
	}


    span {
    	position: relative;
    	z-index: 2;
    }
	strong {
		text-transform: none;
		letter-spacing: 0;
		color: $gray-light;
		font-weight: normal;
	}

	&:hover span {
		color: $gray-darker;
	}
}


.btn-next::after {
	transition: transform $transition-time $transition-bez;
	content: "";
	position: absolute;
	background: $white;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: scaleX(0);
  	transform-origin: right;
  	z-index: 1;
}

.btn-next:hover::after {
	transform: scaleX(1);
	transform-origin: left;
	transition-delay: 0s;
}


.btn-info-ext {
	font-size: 9px;
	background: $gray-dark;
	text-transform: uppercase;
	border: 1px solid $gray;
	padding: 0 2px;
    margin: 0 5px;
    color: $white;

    &:hover {
    	background: $white;
    	color: $gray-dark;
    	border-color: transparent;
    }
}


.btn-close-video {
	transition: background-color $transition-time ease-in-out;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	position: absolute;
	background-color: $brand-primary;
	top: 30px;
	right: 35px;
	border: 0;
	outline: none;
	color: $white;
	text-align: center;
	line-height: 40px;
	cursor: pointer;

	&:hover {
		background-color: $gray-dark;
	}
}

