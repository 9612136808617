// Start off all hidden, like
body {
	transition: opacity 0.5s ease-in-out;
	background: $gray-darker;
	opacity: 0;
	overflow-y: scroll;

	// but when we're ready to go, let it rip
	&.page-ready {
		opacity: 1;
	}
}

img {
	@extend .img-fluid;
}

a:link,
a:active,
a:visited,
a:hover {
	text-decoration: none;
	cursor: pointer;
}

h1, h2, h3, h4, h5 {
	font-family: $font-family-sans-serif;

	span {
		font-family: $font-family-serif;
	}
}

.container-fluid {
	width: 100%; // just in case Flex is messing this up
}

.main-content {
	margin-top: $navbar-height;
	color: $white;

	@include media-breakpoint-up(md) {
		margin-top: 0;
		margin-left: $navbar-height;
	}
}


#loading-cover {
	transition: transform ($transition-time / 2) ease-in-out, opacity ($transition-time / 2) ease-in-out;
	transform: translate(0, 30px);
	position: fixed;
	opacity: 1;
	top: 20%;
	left: 50%;
	z-index: $zindex-tooltip;
	width: 30px;
	height: 30px;
	margin-left: 10px;

	&.hello-app {
		transform: translate(0, 0);
		opacity: 0;
	}
}


circle.rotate {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-width: 30px;
  stroke: rgba(255, 255, 255, 0.25);
  fill: transparent;
  stroke-dasharray: 600;
  stroke-dashoffset: 600;
  animation: anim-circle-border ($transition-time * 3) ease-in-out infinite alternate;
}


// PERCENT CIRCLE ANIMATION
@keyframes anim-circle-border {
  0% {
    stroke-dashoffset: 600;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -600;
  }
}